<script lang="ts" setup>
interface IndicatorProps {
  value: number
  border?: boolean
}

const { value = 0, border = false } = defineProps<IndicatorProps>()

const computedValue = computed(() => {
  return value > 99 ? '99+' : value
})

const style = computed(() => ({
  wrapper: [
    'absolute -top-1 -right-1 bg-t-bg text-t-text rounded-full h-5 flex items-center justify-center px-1 min-w-[1.25rem] text-center',
    {
      'ring-2 ring-bb': border,
    },
  ],
  text: 'leading-none text-xs font-bold',
}))
</script>

<template>
  <div v-if="value > 0" :class="style.wrapper">
    <span :class="style.text">{{ computedValue }}</span>
  </div>
</template>
